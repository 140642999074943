import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';

import config from './aws-exports';

Amplify.configure(config);


/**
 * This is the entry point of the React application.
 * It renders the App component into the DOM element with the id 'root'.
 */

// Create a root for rendering the React application
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component into the root
root.render(<App />);