import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

// Create a context for authentication
const AuthContext = createContext();

// AuthProvider component to provide authentication context to the app
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // State to store the authenticated user
  const [loading, setLoading] = useState(true); // State to manage loading state

  // Check for the current authenticated user when the component mounts
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user); // Set the user if authenticated
        setLoading(false); // Set loading to false
      })
      .catch(() => {
        setUser(null); // Set user to null if not authenticated
        setLoading(false); // Set loading to false
      });
  }, []);

  // Function to handle sign in
  const signIn = async () => {
    try {
      await Auth.federatedSignIn(); // Use federated sign-in
    } catch (error) {
      console.error('Error signing in', error);
    }
  };

  // Function to handle sign out
  const signOut = async () => {
    try {
      await Auth.signOut(); // Sign out the user
      setUser(null); // Set user to null
    } catch (error) {
      console.error('Error signing out', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

