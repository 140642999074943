import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BuildList from './pages/BuildList';
import BuildDetails from './pages/BuildDetails';
import ComponentList from './pages/ComponentList';
import ComponentDetails from './pages/ComponentDetails';
import Navbar from './components/Navbar';
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import './styles.css';
import { AuthProvider } from './services/AuthContext';
import ProtectedRoute from './services/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import OverviewPage from './pages/OverviewPage';


config.oauth.redirectSignIn = `${window.location.origin}/`;

config.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(config);


/**
 * App component
 * The main entry point of the application.
 * Sets up the routing and renders the appropriate components based on the URL.
 */
const App = () => {
  return (
    <AuthProvider>
    <Router>
      <div className="app">
        <Navbar /> {/* Render the navigation bar */}
        <main>
          <Routes>
            {/* Define the routes and their corresponding pages */}
            <Route path="/login" element={<LoginPage />} />
            <Route element={<ProtectedRoute/>}>
            <Route path="/" element={<OverviewPage/>} />
            <Route path="/:platform" element={<OverviewPage/>} />
            <Route path="/:platform/builds" element={<BuildList/>} />
            <Route path="/:platform/builds/:buildId" element={<BuildDetails />} />
            <Route path="/:platform/components" element={<ComponentList />} />
            <Route path="/:platform/component/:testName" element={<ComponentDetails />} />
            </Route> 
          </Routes>
        </main>
        <footer className="footer"></footer>
      </div>
    </Router>
    </AuthProvider>
  );
};

export default App;