import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchBuilds } from '../services/api';
import { TailSpin } from 'react-loader-spinner';
import '../styles.css';

/**
 * BuildList component
 * Renders a list of builds fetched from the API.
 * Clicking on a build item navigates to the corresponding build page.
 */
const BuildList = () => {
  const { platform } = useParams(); // Get the platform from the URL params
  const [builds, setBuilds] = useState([]); // State to store the list of builds
  const [sortMethod, setSortMethod] = useState('buildIdDesc'); // State to control sorting
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const buildsPerPage = 20;

  // Fetch the builds from the API
  useEffect(() => {
    fetchBuilds(platform)
      .then(response => {
        setBuilds(response);
      })
      .catch(error => {
        console.error("Error fetching builds:", error);
      });
  }, []);

  // Handle sort method change
  const handleSortChange = (event) => {
    setSortMethod(event.target.value);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Sort the builds based on the selected sort method
  const sortedBuilds = [...builds].sort((a, b) => {
    if (sortMethod === 'buildIdDesc') {
      return b.buildId - a.buildId
    } else if (sortMethod === 'buildIdAsc') {
      return a.buildId - b.buildId;
    } else {
      return 0;
    }
  });

  // Calculate the indexes for the current page of builds
  const indexOfLastBuild = currentPage * buildsPerPage;
  const indexOfFirstBuild = indexOfLastBuild - buildsPerPage;
  const currentBuilds = sortedBuilds.slice(indexOfFirstBuild, indexOfLastBuild);

  const totalPages = Math.ceil(sortedBuilds.length / buildsPerPage); // Calculate the total number of pages

  // Show a loading spinner while waiting for the builds
  if (!builds.length) return (
    <div className='spinner'>
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#161A1E"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );

  return (
    <div className="build-list">
      <h2>Builds</h2>
      <div className="controls">
        <label>
          Sort By:
          <select value={sortMethod} onChange={handleSortChange}>
            <option value="buildIdDesc">Newest First</option>
            <option value="buildIdAsc">Oldest First</option>
          </select>
        </label>
      </div>
      <ul>
        {/* Render the current page of builds */}
        {currentBuilds.map(build => (
          <li key={build.buildId} className="build-item">
            <Link to={`/${platform}/builds/${build.buildId}`}>
              <div className="build-header">
                <h3>{build.name}</h3>
              </div>
              <div className="build-details">
                <p><strong>Number of Benchmarks:</strong> {build.numBenchmarks}</p>
                <p><strong>Date:</strong> {build.date}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <div className="pagination">
        {/* Render pagination buttons */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BuildList;