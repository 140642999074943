
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { fetchRecentBuilds } from '../services/api';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import '../styles.css';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

// Register the required chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
);

/**
 * OverviewPage component
 * Displays an overview of recent builds with a line chart visualization
 * and a list of benchmarks to toggle visibility in the chart.
 */
const OverviewPage = () => {
  // State variables
  const [builds, setBuilds] = useState([]); // Recent builds data
  const [data, setData] = useState([]); // Unique benchmark data
  const [selectedMetric, setSelectedMetric] = useState('P50'); // Selected metric for the chart
  const [numBuilds, setNumBuilds] = useState(10); // Number of builds to display
  const [buildIds, setBuildIds] = useState([]); // Array of build IDs
  const [selectedBenchmarks, setSelectedBenchmarks] = useState([]); // Array of selected benchmark names
  const chartRef = useRef(null); // Ref for the chart instance
  const navigate = useNavigate(); // React Router navigation function
  const { platform } = useParams(); // Get the platform from the URL params

  /**
   * Fetch recent builds data from the API and initialize state
   */
  useEffect(() => {
    fetchRecentBuilds(platform, numBuilds).then(response => {
      // Sort the builds array based on the buildId property
      const sortedBuilds = response.sort((a, b) => a.buildId - b.buildId);
      setBuilds(sortedBuilds);

      // Create a new array with the sorted buildId values
      const sortedBuildIds = sortedBuilds.map(build => build.buildId);

      // Flatten the benchmarks array from the builds data
      const benchmarks = sortedBuilds.flatMap(build => build.benchmarks);

      // Get unique benchmark names and create an array of benchmark objects
      const uniqueBenchmarks = Array.from(new Set(benchmarks.map(benchmark => benchmark.name)))
        .map(name => benchmarks.find(benchmark => benchmark.name === name));

      // Sort the unique benchmarks by name
      setData(uniqueBenchmarks.sort((a, b) => a.name.localeCompare(b.name)));
      setBuildIds(sortedBuildIds);

      // Set the initial selected benchmarks to all benchmarks
      setSelectedBenchmarks(uniqueBenchmarks.map(benchmark => benchmark.name));
    })
    .catch(error => {
      console.error('Error fetching builds:', error);
    });
  }, [numBuilds]);

  /**
   * Handle metric change in the chart
   * @param {Object} event - Event object
   */
  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  /**
   * Handle change in the number of builds to display
   * @param {Object} event - Event object
   */
  const handleNumBuildsChange = (event) => {
    setNumBuilds(parseInt(event.target.value, 10));
  };

  /**
   * Reset the zoom level on the chart
   */
  const handleResetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  /**
   * Zoom in on the chart
   */
  const handleZoomIn = () => {
    if (chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  /**
   * Zoom out on the chart
   */
  const handleZoomOut = () => {
    if (chartRef.current) {
      chartRef.current.zoom(0.9);
    }
  };

  /**
   * Toggle the selection of a benchmark
   * @param {string} benchmarkName - Name of the benchmark
   */
  const handleBenchmarkToggle = (benchmarkName) => {
    setSelectedBenchmarks(prevSelectedBenchmarks =>
      prevSelectedBenchmarks.includes(benchmarkName)
        ? prevSelectedBenchmarks.filter(name => name !== benchmarkName)
        : [...prevSelectedBenchmarks, benchmarkName]
    );
  };

  /**
   * Select all benchmarks
   */
  const handleSelectAll = () => {
    setSelectedBenchmarks(data.map(benchmark => benchmark.name));
  };

  /**
   * Deselect all benchmarks
   */
  const handleDeselectAll = () => {
    setSelectedBenchmarks([]);
  };

  /**
   * Generate chart data based on selected benchmarks and builds
   */
  const chartData = {
    labels: buildIds,
    datasets: data
      .filter(benchmark => selectedBenchmarks.includes(benchmark.name))
      .reduce((acc, benchmark) => {
        const index = acc.findIndex((dataset) => dataset.label === benchmark.name);
        if (index !== -1) {
          acc[index].data.push(builds.map(build => {
            const bm = build.benchmarks.find(b => b.name === benchmark.name);
            return bm ? bm.frameOverrunMs[selectedMetric] : null;
          })[0]);
        } else {
          acc.push({
            label: benchmark.name,
            data: builds.map(build => {
              const bm = build.benchmarks.find(b => b.name === benchmark.name);
              return bm ? bm.frameOverrunMs[selectedMetric] : null;
            }),
            fill: false,
            borderColor: `rgba(${(acc.length * 40) % 255}, ${(acc.length * 60) % 255}, ${(acc.length * 80) % 255}, 1)`,
            borderWidth: 1,
            pointRadius: 3,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label)), // Sort datasets by label alphabetically
  };

  /**
   * Chart options
   */
  const chartOptions = {
    responsive: true,
    maxWidth: 800,
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        }
      },
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          title: (context) => context[0].dataset.label,
          afterTitle: (context) => {
            const buildId = chartData.labels[context[0].dataIndex];
            return `Build ${buildId}`;
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          text: "Frame overrun (ms)",
          display: true
        }
      },
      x: {
        title: {
          text: "Build Number",
          display: true
        }
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const datasetIndex = elements[0].datasetIndex;
        const benchmarkName = chartData.datasets[datasetIndex].label;
        // Navigate to the component's detail page
        navigate(`/${platform}/component/${benchmarkName}`);
      }
    },
  };

  // Show a loading spinner while waiting for the build details
  if (!builds.length) return (
    <div className='spinner'>
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#161A1E"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );

  return (
    <div className="overview-page">
      <h1>Overview</h1>
      <div className="controls">
        <label>
          Select Metric:
          <select onChange={handleMetricChange} value={selectedMetric}>
            <option value="P50">P50</option>
            <option value="P90">P90</option>
            <option value="P95">P95</option>
            <option value="P99">P99</option>
          </select>
        </label>
        <label>
          Number of Builds:
          <select onChange={handleNumBuildsChange} value={numBuilds}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </label>
        <button onClick={handleZoomIn}>Zoom In</button>
        <button onClick={handleZoomOut}>Zoom Out</button>
        <button onClick={handleResetZoom}>Reset Zoom</button>
      </div>
      <Line ref={chartRef} data={chartData} options={chartOptions} />
      <div className="benchmark-toggles">
        <h3>Select Benchmarks:</h3>
        <div className="benchmark-controls">
          <button onClick={handleSelectAll}>Select All</button>
          <button onClick={handleDeselectAll}>Deselect All</button>
        </div>
        <div className="benchmark-list">
          {data.map(benchmark => (
            <div key={benchmark.name} className="benchmark-item">
              <label>
                <input
                  type="checkbox"
                  checked={selectedBenchmarks.includes(benchmark.name)}
                  onChange={() => handleBenchmarkToggle(benchmark.name)}
                />
                {benchmark.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;