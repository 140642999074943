import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { TailSpin } from 'react-loader-spinner';

// Component to protect routes
const ProtectedRoute = () => {
  const { user, loading } = useContext(AuthContext); // Get user and loading state from AuthContext

  if (loading) {
      // Show a loading spinner 
      return (
        <div className='spinner'>
        <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#161A1E"
            wrapperStyle={{}}
            wrapperClass=""
        />
        </div>
    );
  }

  return user ? <Outlet /> : <Navigate to="/login" />; // Return Outlet if authenticated, otherwise Navigate to login
};

export default ProtectedRoute;
