import React, { useContext, useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import mosaic_logo from '../assets/mosaic_logo.png';
import '../styles.css';
import { AuthContext } from '../services/AuthContext';


/**
 * Navbar component
 * Renders the navigation bar with links to pages.
 */
const Navbar = () => {
  const location = useLocation(); // Get the current location from the router
  const { user, signIn, signOut } = useContext(AuthContext);
  const [selectedPlatform, setSelectedPlatform] = useState('mosaic'); // Default platform is 'mosaic'
  const navigate = useNavigate(); // Get the navigation function

  // Load the selected platform from localStorage on mount
  useEffect(() => {
    const storedPlatform = localStorage.getItem('selectedPlatform');
    if (storedPlatform) {
      setSelectedPlatform(storedPlatform);
      navigate(`/${storedPlatform}`, { replace: true }); // Reroute to the new URL and replace the current entry
    }
  }, []);

  const handlePlatformChange = (event) => {
    const newPlatform = event.target.value;
    setSelectedPlatform(newPlatform);
    localStorage.setItem('selectedPlatform', newPlatform); // Store the selected platform in localStorage
    navigate(`/${newPlatform}`, { replace: true }); // Reroute to the new URL and replace the current entry
    window.location.reload(); // Refresh the page
  };
  
  return (
    <nav className="navbar">
      <div className='logo-container'>
        <img src={mosaic_logo} alt='mosaic-logo' style={{ height: '50px' }}></img>
        <h1>Benchmarks Viewer</h1>
      </div>
      <ul>
        <li>
          <Link to={`/${selectedPlatform}`} className={location.pathname === `/${selectedPlatform}` ? 'active-link' : ''}>
            Overview
          </Link>
        </li>
        <li>
          <Link to={`/${selectedPlatform}/builds`} className={location.pathname === `/${selectedPlatform}/builds` ? 'active-link' : ''}>
            Builds
          </Link>
        </li>
        <li>
          <Link to={`/${selectedPlatform}/components`} className={location.pathname === `/${selectedPlatform}/components` ? 'active-link' : ''}>
            Components
          </Link>
        </li>
        <li>
          <select value={selectedPlatform} onChange={handlePlatformChange}>
            <option value="mosaic">Mosaic</option>
            <option value="legacy">Legacy</option>
          </select>
        </li>
        <li>
          <div className="auth-buttons">
            {user ? (
              <button onClick={signOut}>Sign Out</button> // Sign out button
            ) : (
              <button onClick={signIn}>Sign In</button> // Sign in button
            )}
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
