import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../services/AuthContext';

// Login page component
const LoginPage = () => {
  const { signIn } = useContext(AuthContext); // Get signIn function from AuthContext
  const navigate = useNavigate(); // Use navigate to programmatically navigate

  const handleSignIn = async () => {
    await signIn();
    navigate('/mosaic'); // Redirect to overview page after sign in
  };

  return (
    <div className="build-details-page">
      <h2>Please Sign In to access Benchmarks Viewer</h2>
      <button onClick={handleSignIn}>Sign In with Midway</button> {/* Button to trigger sign in */}
    </div>
  );
};

export default LoginPage;



