import { API } from 'aws-amplify';

export const fetchBuilds = async(platform) => {
  return API.get('benchmarksApi', `/builds/${platform}`);
};

export const fetchBuildDetails = async(platform, buildId) => {
  return API.get('benchmarksApi', `/build/${platform}/${buildId}`);
};

export const fetchComponentDetails = async(platform, testName) => {
  return API.get('benchmarksApi', `/benchmark/${platform}/${testName}`);
};

export const fetchComponents = async(platform) => {
  return API.get('benchmarksApi', `/benchmarks/${platform}`);
};

export const fetchRecentBuilds = async(platform, numBuilds) => {
  return API.get('benchmarksApi', `/recent-builds/${platform}/${numBuilds}`);
};