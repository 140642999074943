import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchComponentDetails } from '../services/api';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { TailSpin } from 'react-loader-spinner';

ChartJS.register(...registerables);

/**
 * ComponentDetails component
 * Renders the details of a specific Component test, including a graph visual
 * showing the performance metrics (P50, P90, P95, P99) across
 * multiple builds. Clicking on a data point in the chart navigates
 * to the corresponding build page.
 */
const ComponentDetails = () => {
  const { platform, testName } = useParams(); // Get the test name from the URL params
  const navigate = useNavigate(); // Hook to navigate between routes
  const chartRef = useRef(null); // Ref to access the chart instance
  const [test, setTest] = useState(null); // State to store the test details
  const [numBuilds, setNumBuilds] = useState(5); // State to control the number of builds to show

  // Fetch the test details from the API when the component mounts or the test name changes
  useEffect(() => {
    fetchComponentDetails(platform, testName)
      .then(response => {
        setTest(response);
      })
      .catch(error => {
        console.error("Error fetching test details:", error);
      });
  }, [testName]);

  // Handle click on the chart data points
  const handleClick = (event) => {
    const points = chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
    if (points.length) {
      const firstPoint = points[0];
      const buildNumber = chartRef.current.data.labels[firstPoint.index].split(' - ')[0];
      navigate(`/${platform}/builds/${buildNumber}`); // Navigate to the build page for the clicked data point
    }
  };

  // Show a loading spinner while waiting for the test details
  if (!test) return (
    <div className='spinner'>
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#161A1E"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );

  // If there are no test run data, show a message
  if (!test.runs || !Array.isArray(test.runs)) return <div>No test run data available</div>;

  // Sort the test runs in descending order by build number
  const sortedRuns = test.runs.sort((a, b) => b.buildNumber - a.buildNumber);
  // Filter and reverse the runs to show the specified number of builds in chronological order
  const filteredRuns = sortedRuns.slice(0, numBuilds).reverse();

  // Prepare the chart data
  const data = {
    labels: filteredRuns.map(run => `${run.buildNumber} - ${run.date}`),
    datasets: [
      {
        label: 'P50',
        data: filteredRuns.map(run => run.p50),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'P90',
        data: filteredRuns.map(run => run.p90),
        borderColor: 'rgba(153,102,255,1)',
        fill: false,
      },
      {
        label: 'P95',
        data: filteredRuns.map(run => run.p95),
        borderColor: 'rgba(255,159,64,1)',
        fill: false,
      },
      {
        label: 'P99',
        data: filteredRuns.map(run => run.p99),
        borderColor: 'rgba(255,99,132,1)',
        fill: false,
      },
    ],
  };

  // Chart options
  const options = {
    onClick: handleClick, // Attach the click event handler
    scales: {
      x: {
        title: {
          display: true,
          text: 'Build Number - Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Value',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: context => `Build ${context.label.split(' - ')[0]}: ${context.parsed.y}`, // Format the tooltip label
        },
      },
    },
  };

  return (
    <div className="page test-list-page overview-page">
      <h1>{test.name}</h1>
      <label>
        Number of Builds to Show:
        <select value={numBuilds} onChange={e => setNumBuilds(Number(e.target.value))}>
          {[...Array(test.runs.length)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </label>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default ComponentDetails;