import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchComponents } from '../services/api';
import { TailSpin } from 'react-loader-spinner';
import '../styles.css';

/**
 * ComponentList component
 * Renders a list of Components fetched from the API.
 * Provides a search input to filter the tests by name.
 * Clicking on a test card navigates to the corresponding Component page.
 */
const ComponentList = () => {
  const { platform } = useParams(); // Get the platform from the URL params
  const [tests, setTests] = useState([]); // State to store the list of tests
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const navigate = useNavigate(); // Hook to navigate between routes

  // Fetch the Component tests from the API
  useEffect(() => {
    fetchComponents(platform)
      .then(response => {
        setTests(response);
      })
      .catch(error => {
        console.error("Error fetching tests:", error);
      });
  }, []);

  // Filter the tests based on the search term
  const filteredTests = tests.filter(test =>
    test.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle the search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Show a loading spinner while waiting for the component details
  if (!tests.length) return (
    <div className='spinner'>
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#161A1E"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
  
  return (
    <div className="page test-list-page">
      <h2>Components</h2>
      <div className="search-sort-controls">
        <input
          type="text"
          placeholder="Search components"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="test-grid">
        {/* Render a card for each test */}
        {filteredTests.map(test => (
          <div
            className="card test-card"
            key={test.name}
            onClick={() => navigate(`/${platform}/component/${test.name}`)}
          >
            <h3>{test.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComponentList;