import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchBuildDetails } from '../services/api';
import { TailSpin } from 'react-loader-spinner';
import '../styles.css';

/**
 * BuildDetails component
 * Renders detailed information about a specific build, including
 * build metadata, benchmark results, etc.
 */
const BuildDetails = () => {
  const { buildId, platform} = useParams(); // Get the build ID from the URL params
  const [build, setBuild] = useState(null); // State to store the build details
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' }); // State to store the sort configuration

  // Fetch the build details from the API
  useEffect(() => {
    fetchBuildDetails(platform, buildId)
      .then(response => {
        setBuild(response);
      })
      .catch(error => {
        console.error("Error fetching build details:", error);
      });
  }, [buildId]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle sort column change
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sort and filter the test results based on the search term and sort configuration
  const sortedTests = React.useMemo(() => {
    if (!build) return [];

    let sortableItems = [...build.benchmarks];
    sortableItems = sortableItems.filter(test =>
      test.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    sortableItems.sort((a, b) => {
      const aValue = sortConfig.key.split('.').reduce((o, i) => o[i], a);
      const bValue = sortConfig.key.split('.').reduce((o, i) => o[i], b);
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [build, sortConfig, searchTerm]);

  // Get the sort indicator (arrow up or down) for a given column
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
    }
    return '';
  };

  // Show a loading spinner while waiting for the build details
  if (!build) return (
    <div className='spinner'>
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#161A1E"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );

  return (
    <div className="build-details-page">
      <h2>{build.name}</h2>
      <div className="build-info">
        <p><strong>Date:</strong> {build.date}</p>
        <p><strong>Device:</strong> {build.device}</p>
        <p><strong>Total Number of Tests:</strong> {build.benchmarks.length}</p>
        <p><strong>Jenkins URL:</strong> <a href={build.jenkins_url} target="_blank" rel="noopener noreferrer">View on Jenkins</a></p>
      </div>
      <h3>Test Results</h3>
      <div className="search-sort-controls">
        <input
          type="text"
          placeholder="Search benchmarks"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <table className="test-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('name')}>Test Name{getSortIndicator('name')}</th>
            <th onClick={() => handleSort('frameOverrunMs.P50')}>Frame Overrun (P50){getSortIndicator('frameOverrunMs.P50')}</th>
            <th onClick={() => handleSort('frameOverrunMs.P90')}>Frame Overrun (P90){getSortIndicator('frameOverrunMs.P90')}</th>
            <th onClick={() => handleSort('frameOverrunMs.P95')}>Frame Overrun (P95){getSortIndicator('frameOverrunMs.P95')}</th>
            <th onClick={() => handleSort('frameOverrunMs.P99')}>Frame Overrun (P99){getSortIndicator('frameOverrunMs.P99')}</th>
            <th onClick={() => handleSort('frameDurationCpuMs.P50')}>Frame Duration (P50){getSortIndicator('frameDurationCpuMs.P50')}</th>
            <th onClick={() => handleSort('frameDurationCpuMs.P90')}>Frame Duration (P90){getSortIndicator('frameDurationCpuMs.P90')}</th>
            <th onClick={() => handleSort('frameDurationCpuMs.P95')}>Frame Duration (P95){getSortIndicator('frameDurationCpuMs.P95')}</th>
            <th onClick={() => handleSort('frameDurationCpuMs.P99')}>Frame Duration (P99){getSortIndicator('frameDurationCpuMs.P99')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedTests.map(test => (
            <tr key={test.name}>
              <td><Link to={`/${platform}/component/${test.name}`}>{test.name}</Link></td>
              <td>{test.frameOverrunMs.P50}</td>
              <td>{test.frameOverrunMs.P90}</td>
              <td>{test.frameOverrunMs.P95}</td>
              <td>{test.frameOverrunMs.P99}</td>
              <td>{test.frameDurationCpuMs.P50}</td>
              <td>{test.frameDurationCpuMs.P90}</td>
              <td>{test.frameDurationCpuMs.P95}</td>
              <td>{test.frameDurationCpuMs.P99}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BuildDetails;
